const InternalView = () => import("./InternalView.vue");
const InternalCreateOrganizationView = () => import("./InternalCreateOrganizationView.vue");
const InternalOrganizationsView = () => import("./InternalOrganizationsView.vue");
const InternalOrganizationDetailsView = () => import("./InternalOrganizationDetailsView.vue");

const internalRoutes = [
  {
    path: "/internal",
    name: "Internal",
    component: InternalView,
    meta: {
      requiresAuth: true
    },
    redirect: () => {
      return { name: "InternalCreateOrganization" };
    },
    children: [
      {
        path: "create-organization",
        name: "InternalCreateOrganization",
        component: InternalCreateOrganizationView
      }
    ]
  },
  {
    path: "/internal/organizations",
    name: "InternalOrganizationsView",
    component: InternalOrganizationsView,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/internal/organizations/:id",
    name: "InternalOrganizationDetailsView",
    component: InternalOrganizationDetailsView,
    props: true,
    meta: {
      requiresAuth: true
    }
  }
];

export default internalRoutes;
